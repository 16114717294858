import React, { useState } from "react";
import { Link } from "react-router-dom";

function ArticlePage() {
  return (
    <div>
      Article Page <Link to="/">Landing Page</Link>
    </div>
  );
}
export default ArticlePage;
