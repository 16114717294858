import React from "react";
import { GoogleOAuthProvider } from "@react-oauth/google";
import "font-awesome/css/font-awesome.min.css";

import App from "./App";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
const root = createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <GoogleOAuthProvider clientId="603301958584-o7lpj8ho1luvudg3tgr3k8hfpnedqdk2.apps.googleusercontent.com">
      <React.StrictMode>
        <App />
      </React.StrictMode>
    </GoogleOAuthProvider>
  </BrowserRouter>
);
