import React, { useState } from "react";
import Hero from "../../components/Hero/Hero.jsx";
import { Link } from "react-router-dom";
import BgShape from "../../assets/images/bg-shape.png";
import ProductsSection from "../../components/ProductsSection/ProductsSection.jsx";
import Testimonials from "../../components/Testimonials/Testimonials.jsx";
import Footer from "../../components/Footer/Footer.jsx";
import Newsletter from "../../components/Newsletter/Newsletter.jsx";

function LandingPage() {
  return (
    <div>
      <Hero />
      <ProductsSection />
      <Newsletter />
      <Footer />
    </div>
  );
}
export default LandingPage;
