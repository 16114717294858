import React from "react";
import "./Newsletter.css";

function Newsletter(props) {
  return (
    <div class="newsletter" id="newsletter-section">
      <div class="newsletter-main-section">
        <div class="newsletter-main-banner">
          <div>
            {" "}
            <h1 class="main-heading">
              Subscribe to
              <span class="newsletter-main-heading-highlight"> Konark </span>
            </h1>
          </div>
          <div>
            {" "}
            <h3 className="newsletter-tagline">
              Join 100's of existing readers, Stay up-to-date with latest AI
              advancements and Learn AI basics along the way.
            </h3>
          </div>

          <div id="newsletter-subscription">
            <input
              type="text"
              id="newsletter-email-input"
              placeholder="Enter your e-mail"
            ></input>
            <input
              type="submit"
              id="newsletter-subscribe-button"
              value="Subscribe Now"
            ></input>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Newsletter;
