import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";
import "./LoginPage.css";
import { Context } from "../../Context";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import NavBar from "../../components/NavBar/NavBar";
function LoginPage() {
  const [context, setContext] = useContext(Context);
  return (
    <div className="login-wrapper">
      <NavBar />
      <div className="login-container">
        <div className="login-switch-tabs"> Login</div>
        <div className="login-box">
          <div className="login-header-text">Join 6000+ Self Learners</div>
          <FontAwesomeIcon icon="fa-brands fa-google" />
        </div>
      </div>
    </div>
  );
}
export default LoginPage;
