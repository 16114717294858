import React from "react";
import "./Footer.css";

function Footer(props) {
  return (
    <footer class="site-footer">
      <div class="container">
        <div class="footer-row">
          <div class="col-sm-12 col-md-6">
            <h6>About</h6>
            <p class="text-justify"></p>
          </div>

          <div class="col-xs-6 col-md-3">
            <h6>Products</h6>
            <ul class="footer-links">
              <li>
                <a>Konark</a>
              </li>
              <li>
                <a>Sisyphus</a>
              </li>
              <li>
                <a>JigglyPuff</a>
              </li>
            </ul>
          </div>

          <div class="col-xs-6 col-md-3">
            <h6>Quick Links</h6>
            <ul class="footer-links">
              <li>
                <a>About Us</a>
              </li>
              <li>
                <a>Contact Us</a>
              </li>
              <li>
                <a>Contribute</a>
              </li>
              <li>
                <a>Privacy Policy</a>
              </li>
              <li>
                <a>Sitemap</a>
              </li>
            </ul>
          </div>
        </div>{" "}
      </div>
      <div class="container">
        <div class="footer-row">
          <div class="col-md-8 col-sm-6 col-xs-12">
            <p class="copyright-text">
              Copyright &copy; 2024 All Rights Reserved by Vritti Labs.
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
}
export default Footer;
