import React from "react";
import Logo from "../../assets/images/logo.png";
import "./Navbar.css";
import { Link } from "react-router-dom";

function NavBar(props) {
  return (
    <div class="navbar">
      <div class="logo">
        <img src={Logo} class="logo" alt="Vritti Logo"></img>
        VRITTI
      </div>
      <div class="products-bar flex-container">
        <span class="product-item">Konark</span>
        <span class="product-item">Sisyphus</span>
        <span class="product-item">JigglyPuff</span>
      </div>
      <div class="account">
        <button class="nav-button">
          <a href="/#newsletter-section" className="router-link">
            Subscribe
          </a>
        </button>
        {/* <Link to="/login">
          <button class="nav-button login-button">Login</button>
        </Link> */}
      </div>
    </div>
  );
}
export default NavBar;
