import React from "react";
import "./ProductsSection.css";
import SisyphusLogo from "../../assets/images/sisyphus-logo.png";
import JigglyPuffLogo from "../../assets/images/jigglypuff-logo.png";
import KonarkLogo from "../../assets/images/konark-logo.png";

function ProductsSection(props) {
  return (
    <div class="products-section" id="product-section">
      <h2 class="products-section-title"> Our Products</h2>
      <div class="products-section-list">
        <div
          class="product-section-item"
          style={{
            background: `url(${KonarkLogo}) no-repeat center center`,
            backgroundSize: "cover",
          }}
        >
          <div class="product-item-header hover-content">Konark</div>
          <div class="product-item-content hover-content">
            Your essential newsletter for staying up-to-date with the latest
            advancements in AI and discovering innovative strategies to enhance
            your learning experience
          </div>
        </div>
        <div
          class="product-section-item"
          style={{
            background: `url(${SisyphusLogo}) no-repeat center center`,
            backgroundSize: "26rem 26rem",
          }}
        >
          <div class="product-item-header hover-content">Sisyphus</div>
          <div class="product-item-content hover-content">
            Shape Your Learning, Control Your Journey: Discover personalized
            plans and interactive dashboards tailored to your goals.
          </div>
        </div>
        <div
          class="product-section-item"
          style={{
            background: `url(${JigglyPuffLogo}) no-repeat center center`,
            backgroundSize: "cover",
          }}
        >
          <div class="product-item-header hover-content">JigglyPuff</div>
          <div class="product-item-content hover-content">
            Your Ultimate AI Creation Marketplace. Discover, Create, and
            Innovate with the Latest AI Tools and Resources
          </div>
        </div>
      </div>
    </div>
  );
}
export default ProductsSection;
