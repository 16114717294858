import React from "react";
import "./Hero.css";
import { Link } from "react-router-dom";
import NavBar from "../NavBar/NavBar";
import "./Hero.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAnglesDown } from "@fortawesome/free-solid-svg-icons";

function Hero(props) {
  const [email, setEmail] = React.useState("");
  const heroStyle = {
    // background: `url(${BgShape}) no-repeat center center`,
    backgroundSize: "cover",
    height: "100vh",
    width: "100vw",
  };

  return (
    <div style={heroStyle} class="hero">
      <NavBar />
      <div class="main-section">
        <div class="main-banner">
          <h1 class="main-heading">
            <span class="main-heading-highlight">Simplifying </span> AI for
            Everyone
          </h1>
        </div>
        <div class="main-subheading">
          <h3>
            At Vritti Labs, we aim to demystify AI, empowering every individual
            with the knowledge to harness its potential.
          </h3>
        </div>
        <div class="hero-button">
          <a className="main-scroll" href="/#product-section">
            <FontAwesomeIcon icon={faAnglesDown} />
          </a>
        </div>
      </div>
    </div>
  );
}
export default Hero;
