import React, { useState } from "react";
import { Route, Routes } from "react-router-dom";
import LandingPage from "./pages/LandingPage/LandingPage";
import ArticlePage from "./pages/ArticlePage/ArticlePage";
import LoginPage from "./pages/LoginPage/LoginPage";
import { Context } from "./Context.js";

function App() {
  const [context, setContext] = useState({
    loginStatus: false,
    userName: "",
    userProfilePhoto: "",
    userEmail: "",
  });

  return (
    <Context.Provider value={[context, setContext]}>
      <div>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/article" element={<ArticlePage />} />
          <Route path="/login" element={<LoginPage />} />
        </Routes>
      </div>
    </Context.Provider>
  );
}
export default App;
